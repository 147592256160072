import React from "react";
import dateFnsLocaleDa from "date-fns/locale/da";

import { Router, Routes, Route, Loadable } from "@discoverr/web/Router";
import { Bootstrap } from "@discoverr/web/Bootstrap";
import { SpaceAuthLoginPage } from "@discoverr/web/SpaceAuth";
import { useNavigate } from "@discoverr/web/Router";
import { getTheme, ThemeType } from "@discoverr/web/themeOld";
import da from "@discoverr/web/languages/da";

// Assets
const npHero = require("./assets/np-hero.jpeg");

// Constants
const theme = getTheme(
  ThemeType.Light,
  {
    light: "#415661",
    main: "#122c3a",
    dark: "#0c1e28",
    contrastText: "#fff",
  },
  {
    main: "#961128",
  }
);

// Views
const LoadableApp = Loadable(React.lazy(() => import("./views/App")));

// Components
const Login = () => {
  const navigate = useNavigate();
  const handleLoginSuccess = () => {
    navigate("/");
  };

  return (
    <SpaceAuthLoginPage
      title={"NP Plan"}
      subtitle="Login på NP Trucks Cloud"
      image={npHero}
      onSuccess={handleLoginSuccess}
    />
  );
};

//
export const Root = () => {
  return (
    <Bootstrap
      titleTemplate="NP Plan - %s"
      language={da}
      theme={theme}
      dateFnsLocale={dateFnsLocaleDa}
    >
      <Router>
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/*" element={<LoadableApp />} />
        </Routes>
      </Router>
    </Bootstrap>
  );
};
