import React from "react";
import ReactDOM from "react-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";
import { Root } from "./Root";

import { initBackend } from "@discoverr/web/backend";

LicenseInfo.setLicenseKey(
  "a89ee302bcb37badf9b6f67fb342f3ffTz02NjIzMixFPTE3MTU0Mjc0NTM1MjQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

initBackend({
  apiHostname: process.env["REACT_APP_API"] as string,
});

// setTimeout(() => {
//   alert(
//     `${(navigator as any).standalone}: ${getComputedStyle(
//       document.documentElement
//     ).getPropertyValue("--sab")}`
//   );
// }, 3000);
// if (
//   "standalone" in navigator &&
//   (navigator as any).standalone &&
//   /iphone|ipod|ipad/gi.test(navigator.platform) &&
//   /Safari/i.test(navigator.appVersion)
// ) {
// }

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
